









































































import { apiThemePageIndex } from "@/api/shop";
import { copyClipboard } from "@/utils/util";
import { Component, Prop, Vue } from "vue-property-decorator";
import VueQr from "vue-qr";
@Component({
  components: {
    VueQr,
  },
})
export default class ShopIndex extends Vue {
  loading = true;
  indexData = {};
  mnpData = {};
  oaData: any = {};
  client = 1;
  getIndex() {
    apiThemePageIndex({ ...this.$route.query }).then((res) => {
      const { home, mp, oa } = res;
      this.indexData = home;
      this.mnpData = mp;
      this.oaData = oa;
    });
  }
  handleCopy() {
    copyClipboard(this.oaData.uri)
      .then(() => {
        this.$message.success("复制成功");
      })
      .catch((err) => {
        this.$message.error("复制失败");
      });
  }

  created() {
    this.getIndex();
  }
}
